import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./index.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import About from "./pages/About";
import FileFormat from "./pages/FileFormat";
import MatchSummary from "./pages/MatchSummary";
import MatchDetailRedesign from "./pages/MatchDetailRedesign";
import ChartJSRadar from "./pages/ChartJSRadar";
import MatchChartPrototype from "./pages/MatchChartPrototype";
import RadarMatchSummary from "./pages/RadarMatchSummary";
import Algorithm from "./pages/Algorithm";
import Learn from "./pages/Learn";
import MatchDetail2024 from "./pages/MatchDetail2024";
import { Amplify } from "aws-amplify";
import config from "./aws-exports";
import { store, persistor } from "./app/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

Amplify.configure(config);

export default function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />
              <Route path="about" element={<About />} />
              <Route path="file-format" element={<FileFormat />} />
              <Route path="match-summary" element={<MatchSummary />} />
              {/* <Route path="match-detail" element={<MatchDetailRedesign />} /> */}
              <Route path="match-detail" element={<MatchDetail2024 />} />
              <Route path="compare" element={<ChartJSRadar />} />
              <Route path="prototype" element={<MatchChartPrototype />} />
              <Route path="new-ms" element={<RadarMatchSummary />} />
              {/* <Route path='learn' element={<Algorithm />} /> */}
              <Route path="learn" element={<Learn />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
