import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import MatchSummaryCard from "../components/MatchSummaryCard";
import Container from "react-bootstrap/esm/Container";
import Stack from "react-bootstrap/Stack";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import "./RadarMatchSummary.css";
import Button from "react-bootstrap/Button";
import { API } from "aws-amplify";
import jsonData from "./matchsummary.json";
import jsonDetailData from "./matchdetail.json";
import DescriptiveStatistic from "../components/DescriptiveStatistic";
import { useSelector, useDispatch } from "react-redux";
import {
  updateRawInput,
  updateMetaData,
  setDemoMode,
} from "../features/preferenceSlice";
import { Icon } from "@iconify/react";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";
import { Radar } from "react-chartjs-2";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

const dataLabels = [
  "Pareto Optimal",
  "Unvenvious",
  "Weakly Stable Pair",
  "Strongly Stable Pair",
  "Super Stable Pair",
  "Matched",
  // "First Preference",
];

const generateExplanation = (matchData, propertyData) => {
  if (
    !(propertyData?.blockingpairs?.blockingPairCount === 0) &&
    !(propertyData?.blockingpairs?.blockingPairCount > 0)
  ) {
    return "Loading...";
  }
  let explanationString = "";
  console.log(matchData?.algorithm);
  if (matchData?.algorithm === "weaklyStable") {
    explanationString +=
      "This solution uses Irving's weakly stable algorithm.\n\n";
  }
  if (matchData?.algorithm === "stronglyStable") {
    explanationString +=
      "This solution uses Irving's strongly stable algorithm.\n\n";
  }
  if (matchData?.algorithm === "superStable") {
    explanationString +=
      "This solution uses Irving's super stable algorithm.\n\n";
  }
  if (propertyData?.blockingpairs?.blockingPairCount === 0) {
    explanationString += "Solution eliminates unstable matches. \n";
  } else if (propertyData?.blockingpairs?.blockingPairCount > 0) {
    explanationString += "Solution contains unstable matches. \n";
  }
  if (propertyData?.po?.violations === 0) {
    explanationString += "Solution eliminates exchange cycles. \n";
  } else if (propertyData?.po?.violations > 0) {
    explanationString += "Solution contains exchange cycles. \n";
  }
  if (propertyData?.envy?.violations === 0) {
    explanationString += "Solution eliminates envy between agents.";
  } else if (propertyData?.envy?.violations > 0) {
    explanationString += "Solution contains envious agents.";
  }
  console.log(explanationString);
  return explanationString;
};

const fillArr = [
  "rgb(234,67,53,0.2)",
  "rgb(52,168,83,0.2)",
  "rgb(66,133,244,0.2)",
  "rgb(251,188,5,0.2)",
];
const strokeArr = [
  "rgb(234,67,53)",
  "rgb(52,168,83)",
  "rgb(66,133,244)",
  "rgb(251,188,5)",
];

// function generateData({ matchData, propertyData }) {
//   const output = [];
//   console.log(propertyData);
//   for (let i = 0; i < propertyData?.length; i++) {
//     const nAgents =
//       matchData?.matches[i]?.metaData[1] + matchData?.matches[i]?.metaData[2];
//     const matchPO =
//       ((nAgents - propertyData[i]?.properties?.po.agentsInvolved.length) /
//         nAgents) *
//       100;
//     const matchUA =
//       ((nAgents - propertyData[i]?.properties?.envy.numberViolators) /
//         nAgents) *
//       100;
//     const matchSP =
//       ((nAgents - propertyData[i]?.properties?.blockingpairs.agentsInvolved) /
//         nAgents) *
//       100;
//     const matchMA =
//       ((2 * matchData?.matches[i]?.matching.men.length) / nAgents) * 100;
//     const matchFP =
//       (propertyData[i]?.properties?.firstPreferenceMatch / nAgents) * 100;

//     const chartData = [
//       +matchPO.toFixed(2),
//       +matchUA.toFixed(2),
//       +matchSP.toFixed(2),
//       +matchMA.toFixed(2),
//       +matchFP.toFixed(2),
//     ];

//     const outputJSON = {
//       label: `Solution ${i + 1}`,
//       data: chartData,
//       backgroundColor: fillArr[i],
//       borderColor: strokeArr[i],
//       borderWidth: 1,
//     };

//     output.push(outputJSON);

//     // console.log("CHART DATA");
//     // console.log(chartData);
//   }
//   console.log(output);
//   return {
//     labels: ["Thing 1", "Thing 2", "Thing 3", "Thing 4", "Thing 5"],
//     datasets: output,
//   };
// }

const data = {
  labels: ["Thing 1", "Thing 2", "Thing 3", "Thing 4", "Thing 5", "Thing 6"],
  datasets: [
    {
      label: "# of Votes",
      data: [2, 9, 3, 5, 2, 3],
      backgroundColor: fillArr[0],
      borderColor: strokeArr[0],
      borderWidth: 1,
    },
    {
      label: "of Votes",
      data: [5, 2, 3, 2, 9, 3],
      backgroundColor: fillArr[1],
      borderColor: strokeArr[1],
      borderWidth: 1,
    },
  ],
};

const options = {
  redraw: true,
  animation: true,
  // maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "top",
      align: "center",
      labels: {
        font: {
          size: 15,
        },
      },
      tooltips: {
        callbacks: {
          // afterLabel: `%`,
          // label: (item) => `%`,
          label: (context) => {
            let label = "";
            console.log("CONTEXT", context);
            if (context.parsed.r) {
              label = context.parsed.r + "%";
            }
            return "l";
          },
        },
      },
    },
  },
  scales: {
    r: {
      suggestedMin: 0,
      suggestedMax: 100,
      pointLabels: {
        font: {
          size: 12,
        },
      },
    },
  },
};

const OptionSwitch = ({ name, tooltip, index, state, setState }) => {
  return (
    <div className="switch-div">
      <Tippy content={tooltip}>
        <span className="info-span" role="button">
          <Icon
            icon={"lucide:info"}
            width="18"
            // height="23"
            // color="#ff582e"
          />
        </span>
      </Tippy>
      <p style={{ flexGrow: 1, marginLeft: "5px" }}>{name}</p>
      <Form.Group>
        <Form.Check // prettier-ignore
          //   disabled={true}
          type={"switch"}
          id="custom-switch"
        >
          <Form.Check.Input
            type={"checkbox"}
            defaultChecked={state[index]}
            onClick={(e) => {
              const newArr = [...state];
              newArr[index] = e.target.checked;
              setState(newArr);
            }}
          />
        </Form.Check>
      </Form.Group>
    </div>
  );
};

const SolutionCard = ({
  radarFill,
  radarLine,
  solutionNum,
  matchData,
  propertyData,
  demoBool,
}) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);

  return (
    <div className="left-grey-box">
      <div
        style={{
          display: "flex",
          flexShrink: 0,
          minWidth: "40px",
          overflow: "visible",
        }}
      >
        {/* <p style={{ flexGrow: 1, marginLeft: "5px" }}>{description}</p> */}
        <svg height="30" width="30" xmlns="http://www.w3.org/2000/svg">
          <circle
            r="10"
            cx="12"
            cy="12"
            fill={radarFill}
            stroke={radarLine}
            stroke-width="2"
          />
        </svg>
        <div style={{ marginLeft: "10px" }}>
          <p className="title">
            <b>Solution {solutionNum}</b>
          </p>
        </div>
        <div
          role="button"
          style={{
            marginLeft: "auto",
            padding: 0,
            borderColor: "rgb(0,0,0,0)",
          }}
          onClick={() => {
            setOpen(!open);
          }}
        >
          <Icon
            icon={open ? "lucide:chevron-up" : "lucide:chevron-down"}
            width="23"
            height="23"
            // color="#ff582e"
            style={{ marginLeft: "auto" }}
          />
        </div>
      </div>
      <div
        className="content"
        style={open ? { display: "block" } : { display: "none" }}
      >
        <br />
        <p>{generateExplanation(matchData, propertyData)}</p>
        <br />
        <div style={{ display: "flex" }}>
          <Button
            onClick={() => {
              navigate("/match-detail", { state: { matchData, demoBool } });
            }}
            variant="primary"
            style={{
              margin: "5px",
              marginBottom: "10px",
              marginLeft: "auto",
              borderWidth: "0px",
            }}
          >
            View Details
          </Button>
        </div>
      </div>
    </div>
  );
};

function RadarMatchSummary() {
  const [mdata, setMdata] = useState([{}]);
  const [chartJSData, setChartJSData] = useState({
    labels: dataLabels,
    datasets: [],
  });
  const [detailData, setDetailData] = useState([]);
  const [labelFilter, setLabelFilter] = useState([
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    false,
  ]);

  const demoModeFlag = useSelector((state) => state.preference.demoMode);
  const dispatch = useDispatch();

  const [fileName, setFileName] = useState(null);
  const location = useLocation();
  const contentId = location.state?.contentId;
  // const demoBool = location.state?.demoBool;
  const demoBool = demoModeFlag;
  const navigate = useNavigate();
  const handleButtonClick = () => {
    navigate("/compare", { state: { mdata, demoBool } });
  };

  const handleButtonClick2 = () => {
    navigate("/prototype", { state: { mdata } });
  };

  useEffect(() => {
    const fetchData = async () => {
      console.log("demobool", demoBool);
      if (contentId) {
        try {
          const apiData = await API.post(
            "matchxplainAPI",
            `/api/match-summary?content_id=${contentId}`
          );
          setMdata(apiData);
          console.log(apiData);
        } catch (error) {
          console.error("Error fetching data: ", error);
        }
      } else if (demoBool) {
        setMdata(jsonData);
      } else {
        console.log("No content ID or demo mode provided");
      }
    };

    fetchData();
  }, [contentId, demoBool]);

  useEffect(() => {
    const fetchData = async () => {
      console.log("this is demobool", demoBool);
      console.log("LABEL FILTER", labelFilter);
      if (demoBool) {
        let demoDataArr = [];
        setDetailData(jsonDetailData);

        while (true) {
          if (typeof mdata !== "undefined") {
            for (let i = 0; i < mdata.matches?.length; i++) {
              demoDataArr = [...demoDataArr, jsonDetailData];
              // setDetailData(apiDataArr);
              console.log(jsonDetailData.properties.envy.violations);
              // console.log(apiDataArr);
            }
            setDetailData(demoDataArr);
            console.log(demoDataArr);
            return;
          }
        }
      } else {
        let apiDataArr = [];
        console.log("fetch from match");
        while (true) {
          if (typeof mdata !== "undefined") {
            for (let i = 0; i < mdata.matches?.length; i++) {
              try {
                console.log("fetch from match-detail");
                const apiData = await API.post(
                  "matchxplainAPI",
                  "/api/match-detail",
                  {
                    body: { data: mdata.matches[i] },
                  }
                );
                apiDataArr = [...apiDataArr, apiData];
                // setDetailData(apiDataArr);
                console.log(apiData.properties.envy.violations);
                // console.log(apiDataArr);
              } catch (error) {
                console.error("Error fetching data:", error);
              }
            }
            setDetailData(apiDataArr);
            console.log(apiDataArr);
            console.log(detailData);
            return;
          }
        }
      }
    };

    fetchData();
  }, [mdata, demoBool]); // Include demoBool in the dependency array

  useEffect(() => {
    console.log("USE EFFECT", detailData);
    generateData(mdata, detailData);
  }, [mdata, detailData, labelFilter]);

  const generateData = (matchData, propertyData) => {
    const output = [];
    console.log(propertyData);
    for (let i = 0; i < propertyData?.length; i++) {
      const nAgents =
        matchData?.matches[i]?.metaData[1] + matchData?.matches[i]?.metaData[2];
      const matchPO =
        ((nAgents - propertyData[i]?.properties?.po.agentsInvolved.length) /
          nAgents) *
        100;
      const matchUA =
        ((nAgents - propertyData[i]?.properties?.envy.numberViolators) /
          nAgents) *
        100;
      const matchBP =
        ((nAgents - propertyData[i]?.properties?.blockingpairs.agentsInvolved) /
          nAgents) *
        100;
      const matchStrongBP =
        ((nAgents -
          2 *
            propertyData[i]?.properties?.strongblockingpairs
              .blockingPairCount) /
          nAgents) *
        100;
      const matchSuperBP =
        ((nAgents -
          propertyData[i]?.properties?.superblockingpairs.agentsInvolved) /
          nAgents) *
        100;
      const matchMA =
        ((2 * matchData?.matches[i]?.matching.men.length) / nAgents) * 100;
      const matchFP =
        (propertyData[i]?.properties?.firstPreferenceMatch / nAgents) * 100;

      const chartData = [
        +matchPO.toFixed(2),
        +matchUA.toFixed(2),
        +matchBP.toFixed(2),
        +matchStrongBP.toFixed(2),
        +matchSuperBP.toFixed(2),
        +matchMA.toFixed(2),
        // +matchFP.toFixed(2),
      ];

      const filteredChartData = [];

      for (let i = 0; i < chartData.length; i++) {
        if (labelFilter[i] === true) {
          filteredChartData.push(chartData[i]);
        }
      }

      const outputJSON = {
        label: `Solution ${i + 1}`,
        data: filteredChartData,
        backgroundColor: fillArr[i],
        borderColor: strokeArr[i],
        borderWidth: 2,
      };

      output.push(outputJSON);

      // console.log("CHART DATA");
      // console.log(chartData);
    }

    const filteredLabels = [];

    for (let i = 0; i < dataLabels.length; i++) {
      if (labelFilter[i] === true) {
        filteredLabels.push(dataLabels[i]);
      }
    }
    console.log(output);
    setChartJSData({
      // labels: dataLabels,
      labels: filteredLabels,
      datasets: output,
    });
  };

  return (
    <>
      <p>
        Below are the potential solutions we generated based on your preference
        profiles. The radar map at the center of the screen indicates what
        percentage of agents in a solution have a certain property (properties
        are shown at the vertices of the radar map). Generally, well-rounded and
        preferrable solutions have a larger respective area on the chart. You
        can customize which properties are on the radar map by using the chart
        options.
      </p>
      <br />
      <div className="top-div">
        <div className="left-white-box">
          {typeof mdata.matches?.length === undefined ? (
            <p>Loading...</p>
          ) : (
            <></>
          )}
          {mdata.matches?.map((object, i) => {
            console.log("data", data);
            return (
              <SolutionCard
                radarFill={fillArr[i]}
                radarLine={strokeArr[i]}
                solutionNum={i + 1}
                matchData={object}
                propertyData={detailData[i]?.properties}
                demoBool={demoBool}
              />
            );
          })}
        </div>
        <div className="chart-div">
          <Radar
            data={chartJSData}
            style={{ height: "75vh" }}
            options={options}
          />
        </div>
        <div style={{ width: "22%" }}>
          <div className="grey-box">
            <p className="title">
              <b>Chart Options</b>
            </p>
            <hr className="title-hr" />
            <OptionSwitch
              name="Efficient Participants"
              tooltip="A participant is efficient if they can't get a more-favored partner without giving another participant a less-favored partner. This notion of efficiency is often called Pareto efficiency."
              index={0}
              state={labelFilter}
              setState={setLabelFilter}
            />
            <OptionSwitch
              name="Unenvious Participants"
              tooltip="A participant is unenvious if they are matched with their most-preferred partner."
              index={1}
              state={labelFilter}
              setState={setLabelFilter}
            />
            <OptionSwitch
              name="Participants in Weakly Stable Pair"
              tooltip="A participant is in a weakly stable pair if there aren't any participants in the pair which mutually favor another participant over their current partner."
              index={2}
              state={labelFilter}
              setState={setLabelFilter}
            />
            <OptionSwitch
              name="Participants in Strongly Stable Pair"
              tooltip="A participant is in a strongly stable pair if there aren't any participants in the pair in which one participant strictly prefers another partner and that partner weakly prefers the participant over their current partner. This is a stronger measure of stability."
              index={3}
              state={labelFilter}
              setState={setLabelFilter}
            />
            <OptionSwitch
              name="Participants in Super Stable Pair"
              tooltip="A participant is in a super stable pair if there aren't any partners in the pair in which one partner and another participant prefer each other just as much as their current partners. This is the strongest measure of stability."
              index={4}
              state={labelFilter}
              setState={setLabelFilter}
            />
            <OptionSwitch
              name="Matched Participants"
              tooltip="A participant is matched if they have been assigned partner(s) in the solution."
              index={5}
              state={labelFilter}
              setState={setLabelFilter}
            />
            {/* <OptionSwitch
              name="Agents Matched w/ First Preference"
              index={6}
              state={labelFilter}
              setState={setLabelFilter}
            /> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default RadarMatchSummary;
